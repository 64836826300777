import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { stores } from "./stores";

/**
 * This file contains the API agent, which is responsible for making requests to our backend.
 * It uses the superagent library to make requests, and superagent-promise to handle promises.
 *
 * How it works:
 * - The requests object contains common request methods for different HTTP verbs.
 * - The other objects use these methods to define specific API requests, e.g., Auth, User, Entity, etc.
 * - The tokenPlugin function sets the authorization token in requests, if it is available.
 * - The handleErrors function is used to handle errors returned from API requests.
 * - The responseBody function extracts the response body from API responses.
 * - The API_ROOT constant defines the root URL for the API, which is used in all requests.
 * - The encode function is used to encode URI components.
 *
 * How to create a new API request:
 * - If the HTTP verb is not already defined in the requests object, add it there.
 * - Create a new object in this file for the specific API request, e.g., Auth, User, Entity, etc, or add it to an existing object.
 * - Define a function in the object that uses the request method from the requests object.
 * - Export the object at the end of the file, if it is a new object.
 * - Import the object where you need to use the API request.
 * - Call the function in the object to make the API request.
 *
 * Example:
 * - To get the scheduled appointments for a professional, you can add a new request to the Professional object:
 * - Define a function in the Professional object that uses the requests.get method:
 * - listAppointments: (id) => requests.get(`professionals/${encode(id)}/appointments/`
 * Note: This endpoint has to be implemented in the backend first, and the response should be handled accordingly.
 */

const encode = encodeURIComponent;

// Define the root URL for the API
export const API_ROOT = process.env.BASE_URL + "api/";

// Create a superagent instance with promise support
const superagent = superagentPromise(_superagent, window.Promise);

// Function to handle errors returned from API requests
const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    // If the error is due to unauthorized access (status 401),
    // log out the user and redirect to the login page
    stores.userStore.logout();
    console.error("BACKEND", err.message);
    window.location.href = "/login";
  }
  return err;
};

// Function to extract response body from API responses
const responseBody = res => ({
  body: res.body,
  status: res.status,
});

// Plugin to set authorization token in requests
const tokenPlugin = req => {
  if (stores.userStore.token) {
    req.set("authorization", `Token ${stores.userStore.token}`);
  }
};

// Object containing common request methods for different HTTP verbs
const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
};

// Object containing authentication-related API requests
const Auth = {
  // Function to login a user
  login: body => requests.post("users/actions/login", body),
  // Function to register a user
  register: body => requests.post("professionals/actions/register", body),
  // Function to request a password reset
  passwordReset: body =>
    requests.post("users/actions/request-password-reset", body),
  // Function to select the entity
  selectEntity: body => requests.post("users/actions/entity-selection/", body),
  // Function to confirm the account
  confirmAccount: body =>
    requests.post("users/actions/confirm-professional", body),
  // Function to reset the password
  resetPassword: body =>
    requests.post("users/actions/password-reset?token=" + body.token, body),
};

// Object containing user-related API requests
const User = {
  // Function to get the current user
  current: type =>
    requests.get(type === 2 ? "professionals/me/" : "managers/me/"),
  // Function to get a user by unique ID
  byUniqueId: (country, number) =>
    requests.get(
      `public/persons?unique_id_country=${encode(country)}&unique_id_number=${encode(number)}`
    ),
  // Function to save user data
  save: (type, user) =>
    requests.patch(type === 2 ? "professionals/me/" : "managers/me/", user),
};

// Object containing entity-related API requests
const Entity = {
  // Function to get a user's entities, given their username
  list: () => requests.get(`public/entities`),
  // Function to get an entity by ID
  get: id => requests.get(`entities/${encode(id)}/`),
  // Function to add a new entity to a professional
  add: entityData => {
    return requests.post(`entities/actions/add`, entityData);
  },
};

// Object containing professional-related API requests
const Professional = {
  // Function to get a list of professionals
  list: () => requests.get(`professionals/`),
  // Function to get a specific professional by ID
  get: id => requests.get(`professionals/${encode(id)}/`),
  // Function to add a new professional
  add: body => requests.post(`professionals/actions/add-professional`, body),
  // Function to set the status of a professional's account
  setStatus: (id, body) =>
    requests.post(
      `professionals/${encode(id)}/actions/set-account-status/`,
      body
    ),
};

// Object containing client-related API requests
const Client = {
  // Function to get a list of clients (patients)
  list: () => {
    // Get a list of clients and return the response
    return requests.get(`patients/`).then(response => {
      return response;
    });
  },
  // Function to get a specific client (patient) by ID
  get: id => requests.get(`patients/${encode(id)}/`),
  // Function to get the body weight evolution of a client (patient)
  getBodyWeightEvo: id =>
    requests.get(`entity-patients/${encode(id)}/body-weight-evolution/`),
  // Function to get the anamnesis of a client (patient)
  getAnamnesis: id => requests.get(`anamnesis/${encode(id)}/`),
  // Function to list appointments of a client (patient)
  listAppointments: (id, page, pageSize) =>
    requests.get(
      `appointments/?page=${encode(page)}&page_size=${encode(pageSize)}&patientId=${encode(id)}`
    ),
  // Function to get a specific appointment of a client (patient) by ID
  getAppointment: id => requests.get(`appointments/${encode(id)}`),
  // Function to add a new client (patient)
  add: body => requests.post(`patients/`, body),
  // Function to update a client (patient)
  update: (id, body) => requests.put(`patients/${encode(id)}/`, body),
  // Function to delete a client (patient)
  delete: id => requests.del(`patients/${encode(id)}/`),
};

const Countries = {
  list: () => {
    // Get a list of clients and return the response
    return requests.get(`countries`).then(response => {
      return response;
    });
  },
  getLabel: label => requests.get(`countries/??label=${encode(label)}/`),
};

export default {
  Auth,
  User,
  Entity,
  Professional,
  Client,
  Countries,
};
