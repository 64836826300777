import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { stores } from "../../stores";
import AuthSelectEntity from "../../views/authentication/authForms/AuthSelectEntity";
import { useTranslate } from "../../translations/translate";

const SelectEntity = observer(() => {
  const translate = useTranslate();

  return (
    <Box p={4} sx={{ zIndex: "2", maxWidth: 500, width: "100%" }}>
      <AuthSelectEntity
        title={translate("hi_user", {
          name: stores.userStore.currentUser?.name,
        })}
      />
    </Box>
  );
});

export default SelectEntity;
