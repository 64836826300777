import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { stores } from "../../../stores";
import agent from "../../../agent";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { countryToFlag } from "../../../utils";
import { useTranslate } from "../../../translations/translate";

const AuthRegisterEntity = ({ title }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [showaddEntityError, setaddEntityError] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setloading] = useState(false);
  const [APIErrors, setAPIErrors] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
  }, []);

  useMemo(() => {
    const getCountries = async () => {
      setloading(true);
      try {
        const result = await agent.Countries.list();
        setAllCountries(result.body);
      } catch (error) {
        setAPIErrors(true);
      }
      setloading(false);
    };

    getCountries();
  }, []);

  const addEntitySchema = Yup.object().shape({
    entityName: Yup.string().required(translate("entity_name_required")),
    vatNumber: Yup.string().required(translate("tax_number_required")),
    entityCountry: Yup.object()
      .nullable()
      .required(translate("yup_country_required"))
      .shape({
        code: Yup.string().required(),
        label: Yup.string().required(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      entityName: "",
      vatNumber: "",
      entityCountry: null,
    },
    validationSchema: addEntitySchema,
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {
        entity: {
          country: values.entityCountry.code,
          name: values.entityName,
          vat_number: values.vatNumber,
        },
      };

      stores.userStore
        .addEntity(submissionValues)
        .then(result => {
          switch (result) {
            case true:
              navigate("/dashboard");
              break;
            case false:
            case null:
              setaddEntityError(true);
              break;
            default:
              console.error("Unexpected result:", result);
          }
        })
        .catch(error => {
          console.error("Error during entity registration:", error);
          setaddEntityError(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <>
      <Stack direction="column" spacing={1} mb={1}>
        {title ? (
          <Typography variant="headingConsole" color={"secondary"}>
            {title}
          </Typography>
        ) : null}
        {APIErrors && (
          <Alert variant="filled" severity="error" my={2}>
            {translate("error_contacting_server_try_again")}
          </Alert>
        )}
        <Typography variant="headingConsoleSubtitle" color={"secondary"}>
          {translate("details_about_entity")}
        </Typography>
      </Stack>
      {showaddEntityError && (
        <Alert variant="filled" severity="error" my={2}>
          {translate("error_registering_entity")}
        </Alert>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={2} my={4}>
            <CustomTextField
              fullWidth
              variant="outlined"
              label={translate("entity_name")}
              {...getFieldProps("entityName")}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.entityName && errors.entityName)}
              helperText={touched.entityName && errors.entityName}
            />
            <CustomTextField
              fullWidth
              variant="outlined"
              label={translate("fiscal_id")}
              {...getFieldProps("vatNumber")}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.vatNumber && errors.vatNumber)}
              helperText={touched.vatNumber && errors.vatNumber}
            />
            <Autocomplete
              fullWidth
              options={allCountries}
              loading={loading}
              loadingText={translate("loading_countries")}
              autoHighlight
              value={values.entityCountry}
              getOptionLabel={option => option.label}
              onChange={(event, value) => setFieldValue("entityCountry", value)}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  option =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()) ||
                    option.code.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    "& > span": { mr: "10px", fontSize: 18 },
                  }}
                  {...props}
                >
                  <span>{countryToFlag(option.code)}</span>
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={params => (
                <CustomTextField
                  {...params}
                  label={translate("country")}
                  variant="outlined"
                  aria-label={translate("country")}
                  autoComplete="off"
                  inputprops={{
                    ...params.inputprops,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  error={touched.entityCountry && Boolean(errors.entityCountry)}
                  helperText={touched.entityCountry && errors.entityCountry}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              disabled={allCountries.length === 0}
            />
          </Stack>

          <Stack direction="row" spacing={2} mt={1} justifyContent="flex-end">
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              onClick={() => {
                stores.userStore.logout();
                navigate("/login");
              }}
              type="button"
            >
              {translate("cancel")}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {translate("confirm")}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AuthRegisterEntity;
