import { StrictMode, Suspense } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { isElectron } from "./constants";
import { Box } from "@mui/material";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import typography from "./theme/Typography";
import { LightThemeColors } from "./theme/LightThemeColors";
import { baselightTheme } from "./theme/DefaultColors";
import { ukUA } from "@mui/material/locale";
import { shadows } from "./theme/Shadows";
import components from "./theme/Components";
import Spinner from "./views/spinner/Spinner";
import ScrollToTop from "./components/shared/ScrollToTop";
import Router from "./routes/Router";
import { LanguageProvider } from "./contexts/LanguageContext";

// Use either HashRouter or BrowserRouter based on the environment
const ElectronRouter = isElectron ? HashRouter : BrowserRouter;

const App = observer(() => {
  // Initialize the theme. In the future, we should move it to a separate file and observe the theme changes (light to dark and vice versa)
  const themeOptions = LightThemeColors.find(
    theme => theme.name === "ORANGE_THEME"
  );
  const baseMode = {
    palette: {
      mode: "light",
    },
    shape: {
      borderRadius: 7,
    },
    shadows: shadows,
    typography: typography,
  };

  const theme = createTheme({
    ...baseMode,
    ...baselightTheme,
    ...ukUA,
    ...themeOptions,
    direction: "ltr",
  });

  theme.components = components(theme);

  return (
    <StrictMode>
      <Suspense fallback={<Spinner full={true} />}>
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <Box sx={{ width: "100%", height: "100%" }}>
              <ElectronRouter>
                <ScrollToTop>
                  <Router />
                </ScrollToTop>
              </ElectronRouter>
            </Box>
          </LanguageProvider>
        </ThemeProvider>
      </Suspense>
    </StrictMode>
  );
});

// create the root element
const root = createRoot(document.getElementById("root"));
// render the App component on the root element
root.render(<App />);
