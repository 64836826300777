import { makeAutoObservable } from "mobx";

class CustomizerStore {
  activeDir = "ltr";
  activeMode = "light"; // This can be light or dark
  activeTheme = "BLUE_THEME"; // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
  SidebarWidth = 270;
  MiniSidebarWidth = 87;
  TopbarHeight = 70;
  isLayout = "boxed"; // This can be full or boxed
  isCollapse = false; // to make sidebar Mini by default
  isSidebarHover = false;
  isMobileSidebar = false;
  isHorizontal = false;
  isLanguage = "en";
  isCardShadow = true;
  borderRadius = 7;

  constructor() {
    makeAutoObservable(this);
  }

  setTheme(theme) {
    this.activeTheme = theme;
  }

  setDarkMode(mode) {
    this.activeMode = mode;
  }

  setDir(dir) {
    this.activeDir = dir;
  }

  setLanguage(language) {
    this.isLanguage = language;
  }

  setCardShadow(value) {
    this.isCardShadow = value;
  }

  toggleSidebar() {
    this.isCollapse = !this.isCollapse;
  }

  hoverSidebar(value) {
    this.isSidebarHover = value;
  }

  toggleMobileSidebar() {
    this.isMobileSidebar = !this.isMobileSidebar;
  }

  toggleLayout(layout) {
    this.isLayout = layout;
  }

  toggleHorizontal(horizontal) {
    this.isHorizontal = horizontal;
  }

  setBorderRadius(radius) {
    this.borderRadius = radius;
  }
}

export default CustomizerStore;
