import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

const TOKEN_STORAGE_KEY = "@gripwise_embedded_token";

export const EmbeddedContext = createContext(undefined);

export const EmbeddedProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            queryParams.delete('token');
            setIsLoggedIn(true);
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        } else if (!storedToken) {
            navigate('/404');
        }
    }, [token, storedToken, navigate, location.pathname, queryParams]);

    return (
        <EmbeddedContext.Provider value={{ isLoggedIn }}>
            {children}
        </EmbeddedContext.Provider>
    );
};