import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import agent from "../../../agent";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { stores } from "../../../stores";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { countryToFlag } from "../../../utils";
import { useTranslate } from "../../../translations/translate";
import { NAME_REGEX } from "../../../constants";

const AuthRegisterUser = ({ title, subtitle }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [countries, setCountries] = useState([]);
  const [showError, setError] = useState(false);
  const [loading, setloading] = useState(false);

  // Fetch countries when the component mounts
  useMemo(() => {
    const getCountries = async () => {
      setloading(true);
      try {
        const result = await agent.Countries.list();
        setCountries(result.body);
      } catch (error) {
        console.error("Error during fetching countries:", error);
        setError(translate("error_contacting_server_try_again"));
      }
      setloading(false);
    };

    getCountries();
  }, []);

  const RegisterUserSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(translate("yup_name_required"))
      .matches(NAME_REGEX, translate("yup_name_invalid")),
    email: Yup.string()
      .email(translate("invalid_email"))
      .required(translate("yup_email_required")),
    country: Yup.object().required(translate("yup_country_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: null,
    },
    validationSchema: RegisterUserSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Manually trim the name field before submitting
      const trimmedValues = {
        ...values,
        name: values.name.trim(), // Trim the name here
        country: values.country.code,
      };

      stores.userStore
        .register(trimmedValues)
        .then(result => {
          switch (result) {
            case true:
              navigate("/register-check-email");
              break;
            case false:
            case null:
              setError(true);
              break;
            default:
              console.error("Unexpected result:", result);
          }
        })
        .catch(error => {
          console.error("Error during login:", error);
          setError(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <>
      <Typography variant="headingConsole" color={"secondary"}>
        {title}
      </Typography>
      {showError && (
        <Alert variant="filled" severity="error" my={2}>
          {showError}
        </Alert>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} mt={2}>
            <CustomTextField
              fullWidth
              {...getFieldProps("name")}
              variant="outlined"
              label={translate("label_name")}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <CustomTextField
              fullWidth
              {...getFieldProps("email")}
              variant="outlined"
              label={translate("email")}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            <Autocomplete
              fullWidth
              options={countries}
              loading={loading}
              loadingText={translate("loading_countries")}
              autoHighlight
              value={values.country}
              getOptionLabel={option => option.label}
              onChange={(event, value) => setFieldValue("country", value)}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  option =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()) ||
                    option.code.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    fontSize: 15,
                    "& > span": { mr: "10px", fontSize: 18 },
                  }}
                  {...props}
                >
                  <span>{countryToFlag(option.code)}</span>
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={params => (
                <CustomTextField
                  {...params}
                  label={translate("country")}
                  variant="outlined"
                  aria-label={translate("country")}
                  autoComplete="off"
                  inputprops={{
                    ...params.inputprops,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              disabled={countries.length === 0}
            />
            <Typography color="textSecondary" variant="body1">
              {translate("already_have_an_account")}{" "}
              <Link component={RouterLink} to="/login" color="primary">
                {translate("login")}
              </Link>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mt={1} justifyContent="flex-end">
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              onClick={() => {
                navigate("/login");
              }}
            >
              {translate("cancel")}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {translate("register")}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      {subtitle}
    </>
  );
};

export default AuthRegisterUser;
