import UserStore from "./userStore";
import ProfessionalStore from "./professionalStore";
import PatientStore from "./patientStore";
import CustomizerSlideStore from "./customizerSlideStore";

export function resetStores() {
  for (const store in stores) {
    stores[store].reset();
  }
}

export const stores = {
  userStore: new UserStore(),
  professionalStore: new ProfessionalStore(),
  patientStore: new PatientStore(),
  customizerStore: new CustomizerSlideStore(),
};
