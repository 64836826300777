import { action, makeObservable, observable } from "mobx";
import agent from "../agent";
import { stores } from "./index";

function removeEmpty(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

class ProfessionalStore {
  inProgress = {
    register: false,
    getProfessional: false,
    getAll: false,
  };

  all = [];

  professionalInfo = [];

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      all: observable,
      professionalInfo: observable,
      add: action,
      //get: action,
      getAll: action,
    });
  }

  add = values => {
    this.inProgress.register = true;
    this.errors = undefined;

    return agent.Professional.add(values)
      .then(() => {
        agent.Professional.list().then(
          action(professionals => {
            this.all = professionals;
          })
        );
      })
      .catch(
        action(err => {
          this.errors = err?.response.body;
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.register = false;
        })
      );
  };

  //get = id => {};

  getAll = () => {
    this.inProgress.getAll = true;

    return agent.Professional.list()
      .then(
        action(response => {
          const professionals = response.body;
          this.all = professionals.filter(
            pro => pro.id !== stores.userStore.currentUser.id
          );
        })
      )
      .catch(
        action(err => {
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.getAll = false;
        })
      );
  };
}

export default ProfessionalStore;
