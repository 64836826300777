import dayjs from "dayjs";
import { PT_LANGUAGE } from "../constants";

export const getCurrentSystemLang = () => {
  if (PT_LANGUAGE.includes(navigator.language)) return "pt";
  return "en";

  // return navigator.language;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE11
export const countryToFlag = isoCode =>
  typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;

// check for empty object
export const objEmpty = obj =>
  !Object.values(obj).filter(e => typeof e !== "undefined").length;

export function ageFromBirthday(birthday, referenceDate = new dayjs()) {
  if (!birthday) return null;

  return dayjs(referenceDate).diff(
    dayjs(birthday).format("YYYY-MM-DD"),
    "years",
    false
  );
}

export function objectListToMap(objectList, mapKey) {
  const map = {};
  for (const obj of objectList) {
    map[obj[mapKey]] = obj;
  }

  return map;
}

export function removeEmpty(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

export function skinFoldsById(array, id) {
  return array.find(x => x.skin_fold_id === id);
}

export function downloadSkinfoldCSV(items) {
  const csv = [""];
  csv.push([
    "_id",
    items[0].trial_1.map((s, i) => `trial 1 - ${i}`),
    items[0].trial_2.map((s, i) => `trial 2 - ${i}`),
    items[0].trial_3.map((s, i) => `trial 3 - ${i}`),
    "average",
  ]);

  for (let i = 0; i < items.length; i++) {
    csv.push([
      items[i].skin_fold_id,
      [...items[i].trial_1] || 0,
      [...items[i].trial_2] || 0,
      [...items[i].trial_3] || 0,
      items[i].average || 0,
    ]);
  }

  downloadBlob(arrayToCsv(csv), "skinfold.csv", "text/csv;charset=utf-8;");
}

/** Convert a 2D array into a CSV string
 */
export function arrayToCsv(items) {
  /* return data
        .map(
            row =>
                row
                    .map(String) // convert every value to String
                    .map(v => v.replaceAll('"', '""')) // escape double colons
                    .map(v => `"${v}"`) // quote it
                    .join(',') // comma-separated
        )
        .join('\r\n'); // rows starting on new lines
*/
  let csv;

  // Loop the array of objects
  for (let row = 0; row < items.length; row++) {
    const keysAmount = Object.keys(items[row]).length;
    let keysCounter = 0;

    // If this is the first row, generate the headings
    if (row === 0) {
      // Loop each property of the object
      for (const key in items[row]) {
        // This is to not add a comma at the last cell
        // The '\r\n' adds a new line
        csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");
        keysCounter++;
      }
    } else {
      for (const key in items[row]) {
        csv += items[row][key] + (keysCounter + 1 < keysAmount ? "," : "\r\n");
        keysCounter++;
      }
    }

    keysCounter = 0;
  }

  return csv;
}

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(content, filename, contentType) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  if (!name) return null;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name[0],
  };
}

export const capitalizeFirstLetter = string =>
  typeof String.fromCodePoint !== "undefined"
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;

export const GENDER_TO_INT = genderStr => (genderStr === "male" ? 0 : 1);

export const ETHNICITY_TO_INT = ethnicityStr =>
  ethnicityStr === "caucasian" ? 0 : 1;
