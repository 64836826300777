const typography = {
  fontFamily: "Roboto, sans-serif",
  h1: {
    fontWeight: 600,
    fontSize: "2.25rem",
    lineHeight: "2.75rem",
  },
  h2: {
    fontWeight: 600,
    fontSize: "1.875rem",
    lineHeight: "2.25rem",
  },
  h3: {
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
  },
  h4: {
    fontWeight: 600,
    fontSize: "1.3125rem",
    lineHeight: "1.6rem",
  },
  h5: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.6rem",
  },
  h6: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.2rem",
  },
  button: {
    textTransform: "capitalize",
    fontWeight: 400,
  },
  body1: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.334rem",
  },
  body2: {
    fontSize: "0.75rem",
    letterSpacing: "0rem",
    fontWeight: 400,
    lineHeight: "1rem",
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  headingConsole: {
    fontSize: 60,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 700,
    letterSpacing: "-0.5%",
    lineHeight: "80px",
    "@media (max-width: 600px)": {
      fontSize: 40,
      lineHeight: "50px",
    },
  },
  headingConsoleSubtitle: {
    fontSize: 35,
    fontFamily: "Roboto, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "-0.5%",
    lineHeight: "45px",
    "@media (max-width: 600px)": {
      fontSize: 25,
      lineHeight: "35px",
    },
  },
};

export default typography;
