import { observer } from "mobx-react-lite";
import { Box, Container, Typography } from "@mui/material";

const TermsOfUse = observer(() => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      textAlign="center"
    >
      <Container maxWidth="md">
        <Typography align="center" variant="h1" mb={4} mt={3}>
          Terms of use
        </Typography>
        <Typography align="center" variant="h4" mb={4}>
          Get to know more about our terms of use
        </Typography>
        <Typography variant="body1" pt={1} px={2} color="textSecondary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec
          purus feugiat, molestie ipsum et, consequat nibh. Etiam non elit dui.
          Nullam vel erat sed mi finibus lacinia. Nulla fringilla magna vitae
          scelerisque facilisis. Suspendisse et ex nec libero posuere suscipit.
          Etiam auctor, nisl eget accumsan lacinia, nunc sapien ultricies dolor,
          nec sollicitudin risus orci quis tellus. Nullam sit amet est auctor,
          ultricies mi nec, aliquam nunc. Nullam nec magna non ipsum lacinia
          aliquet. Nullam nec nisi ac nunc
        </Typography>
      </Container>
    </Box>
  );
});

export default TermsOfUse;
