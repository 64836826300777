import { useEffect, useState } from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { stores } from "../../../stores";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslate } from "../../../translations/translate";

const AuthResetPassword = ({ title, subtitle }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [showError, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    if (!token) navigate("/login");
  }, []);

  const ResetPasswordchema = Yup.object().shape({
    password: Yup.string()
      .min(8, translate("password_too_short"))
      .required(translate("password_required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translate("passwords_must_match"))
      .required(translate("password_required")),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordchema,
    onSubmit: (values, { setSubmitting }) => {
      stores.userStore
        .resetPassword(token, values.password)
        .then(result => {
          switch (result) {
            case true:
              setSuccess(true);
              setTimeout(() => {
                navigate("/login", { replace: true });
              }, 5000);
              break;
            case false:
            case null:
              setError(true);
              break;
            default:
              console.error("Unexpected result:", result);
          }
        })
        .catch(error => {
          console.error("Error during login:", error);
          setError(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <>
      <Stack direction="column" spacing={1} mb={1}>
        {title ? (
          <Typography variant="headingConsole" color={"secondary"}>
            {title}
          </Typography>
        ) : null}
        <Typography variant="headingConsoleSubtitle" color={"secondary"}>
          {translate("choose_new_password")}
        </Typography>
      </Stack>
      {showError && (
        <Alert variant="filled" severity="error" my={2}>
          {translate("error_resetting_password")}
        </Alert>
      )}
      {success && (
        <Alert variant="filled" severity="success" my={2}>
          {translate("password_reset_success_redirect")}
        </Alert>
      )}

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack>
            <Box sx={{ marginBottom: "5px", marginTop: "25px" }}>
              <CustomTextField
                fullWidth
                {...getFieldProps("password")}
                type="password"
                variant="outlined"
                label={translate("new_password")}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Box>
            <Box sx={{ marginBottom: "5px", marginTop: "25px" }}>
              <CustomTextField
                fullWidth
                {...getFieldProps("confirmPassword")}
                type="password"
                variant="outlined"
                label={translate("confirm_new_password")}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              component={Link}
              to="/login"
              type="button"
            >
              {translate("cancel")}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {translate("confirm")}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      {subtitle}
    </>
  );
};
export default AuthResetPassword;
