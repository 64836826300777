import { Suspense } from "react";
import Spinner from "../../views/spinner/Spinner";

const Loadable = Component => props => (
  <Suspense fallback={<Spinner full={true} />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
